import { Avatar, Button, CloseIcon, Divider, Flex, Image, Text } from "@fluentui/react-northstar";
import React from "react";

const UserSelected = (props: { avatar: string; email?: string; name: string; handleRemove: (...args: unknown[]) => void }) => {
  return (
    <>
      <Flex gap="gap.small" hAlign="center" vAlign="center">
        <Flex>
          <Avatar image={props.avatar} name={props.name} size="medium" />
        </Flex>
        <Flex hAlign="center" vAlign="center" fill space="between">
          <Flex column>
            <Text content={props.name} weight="regular" size="medium" />
            {props.email && <Text content={props.email} size="smaller" />}
          </Flex>
          <Flex>
            <Button icon={<CloseIcon />} text iconOnly onClick={(...args) => props.handleRemove(args)} />
          </Flex>
        </Flex>
      </Flex>
      <Divider style={{ opacity: "0.25" }} />
    </>
  );
};

export default UserSelected;
