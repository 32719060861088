import { createGroup, deleteGroup, editGroup, getAllGroups } from "../../apis/actions/groupsAction";
import { QUERY_KEY_GROUPS } from "../../const/queryKey";
import { IGroupDTO } from "../../interfaces/IGroup/IGroupDTO";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { IAppDispatch, ReduxStoreState } from "redux/store";
import { queryOptions } from "./queryOptions";
import { useQueryCache } from "./useQueryCache";
import { IGroup } from "../../interfaces/IGroup/IGroup";
import { IQueryCacheData } from "../../interfaces/ICache/IQueryCache";
import { handleExpiredToken, setErrorMessage, useTranslate } from "front";
import { translations } from "../../translations";

export const useGroupsCache = () => {
  const appId = useSelector((s: ReduxStoreState) => s.appConfiguration.appId);
  const dispatch = useDispatch<IAppDispatch>();
  const t = useTranslate(translations);

  const { mutate } = useQueryCache();

  const queryGroups = useQuery<IGroup[]>([QUERY_KEY_GROUPS, appId], getAllGroups, {
    ...queryOptions,
    enabled: !!appId,
    staleTime: Infinity,
    ...handleExpiredToken,
  });

  const mutateCreateGroup = useMutation(async (data: IGroupDTO & { participants: { add: string[]; remove: string[] } }) => createGroup(data), {
    ...handleExpiredToken,
    onSuccess: (res: IGroup) => {
      const dataCache: IQueryCacheData = {
        data: [...(queryGroups.data ?? []), res],
        queryKey: [QUERY_KEY_GROUPS, appId],
      };
      mutate(dataCache);
    },
    onError: () => {
      dispatch(setErrorMessage(t("CreateGroupError")));
    },
  });

  const mutateEditGroup = useMutation(editGroup, {
    ...handleExpiredToken,
    onSuccess: (_: undefined, variable: IGroup & { participants: { add: string[]; remove: string[] } }) => {
      const copyQuery = queryGroups.data ? [...queryGroups.data] : [];
      const modifiedGroupIndex = copyQuery.findIndex((item) => item.id === variable.id);
      if (modifiedGroupIndex === -1) return;
      copyQuery.splice(modifiedGroupIndex, 1, variable);
      const dataCache: IQueryCacheData = {
        data: copyQuery,
        queryKey: [QUERY_KEY_GROUPS, appId],
      };
      mutate(dataCache);
    },
    onError: () => {
      dispatch(setErrorMessage(t("EditGroupError")));
    },
  });

  const mutateDeleteGroup = useMutation(deleteGroup, {
    ...handleExpiredToken,
    onSuccess: (_: undefined, variable: { tenantId: string; contactListId: string; id: string }) => {
      const filteredData = queryGroups.data?.filter((item) => item.id !== variable.id);
      const dataCache: IQueryCacheData = {
        data: filteredData,
        queryKey: [QUERY_KEY_GROUPS, appId],
      };
      mutate(dataCache);
    },
    onError: () => {
      dispatch(setErrorMessage(t("DeleteGroupError")));
    },
  });

  return {
    mutateCreateGroup,
    mutateEditGroup,
    mutateDeleteGroup,
    queryGroups: { ...queryGroups, data: queryGroups.data ?? [] },
  };
};
