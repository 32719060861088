import { findKeyPath, useTranslate } from "front";
import { IAdress } from "../../interfaces/IContact/IAdress";
import { IContact } from "../../interfaces/IContact/IContact";
import { useCallback } from "react";
import { Control, FieldErrors, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { translations } from "../../translations";

export const useAddressInput = (props: {
  defaultValues: Omit<IContact, "updatedAt" | "createdAt">;
  contact: IContact | undefined;
  canModify: boolean;
  control: Control<IContact, any>;
  errors: FieldErrors;
  setValue: UseFormSetValue<IContact>;
  watch: UseFormWatch<IContact>;
}) => {
  const t = useTranslate(translations);

  const handleAddress = useCallback((params: IAdress) => {
    props.setValue(findKeyPath(props.defaultValues, "fullAddress"), params.fullAddress, { shouldDirty: true });
    props.setValue(findKeyPath(props.defaultValues, "lat"), params.lat, { shouldDirty: true });
    props.setValue(findKeyPath(props.defaultValues, "lng"), params.lng, { shouldDirty: true });
  }, []);

  return { t, handleAddress };
};
