import React from "react";
import { useConfigure } from "./Configure.logic";
import { Dropdown, Flex, Loader, Image, Text } from "@fluentui/react-northstar";
import { initialState } from "./Configure.reducer";
import { useMsTeamsSelector, useTranslate } from "front";
import { translations } from "../../translations";
export const Configure = () => {
  const logic = useConfigure();
  const t = useTranslate(translations);
  const { isOnMobile } = useMsTeamsSelector("isOnMobile");

  const renderCreate = () => (
    <div className="configure-container">
      <div>
        <h4>{logic.t("DisplayMode")}</h4>
        <Dropdown data-testid="FUI-input" items={logic.dropDownItems} checkable defaultValue={logic.t(initialState.displayMode)} onChange={logic.handleDropDown} fluid />
      </div>
    </div>
  );

  const renderHelp = () => (
    <div style={{ display: "flex", flexFlow: "column nowrap", justifyContent: "center", alignItems: "center" }}>
      <Text
        style={{
          textAlign: "center",
          marginBottom: "1rem",
        }}
        content={t("ConfigurationHelp")}
      ></Text>
      <Image src={"/images/configuration/help.webp"} />
    </div>
  );
  const renderUpdate = () => (
    <Flex
      fill
      column
      gap={"gap.medium"}
      styles={{
        display: "flex",
        padding: isOnMobile ? "15px" : "0",
        overflowY: "scroll",
      }}
    >
      {renderHelp()}
    </Flex>
  );
  if (!logic.entityId) return renderCreate();
  else {
    return renderUpdate();
  }
};
