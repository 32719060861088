import { AxiosConfig, tryCatch } from "front";
import { IGroup } from "interfaces/IGroup/IGroup";
import { IGroupDTO } from "interfaces/IGroup/IGroupDTO";

const ROUTE = "/contact-lists/";

const createGroupAction = async (data: IGroupDTO & { participants: { add: string[]; remove: string[] } }): Promise<any> => {
  const res = await AxiosConfig.instance.post(`${ROUTE}${data.contactListId}/groups`, data);
  return res.data;
};

const editGroupAction = async (data: IGroup & { participants: { add: string[]; remove: string[] } }): Promise<void> => {
  await AxiosConfig.instance.patch(`${ROUTE}${data.contactListId}/groups/${data.id}`, data);
};

const deleteGroupAction = async (data: { tenantId: string; contactListId: string; id: string }): Promise<void> => {
  await AxiosConfig.instance.delete(`${ROUTE}${data.contactListId}/groups/${data.id}/tenantId/${data.tenantId}`);
};

const getAllGroupsAction = async ({ queryKey }: { queryKey: string[] }): Promise<IGroup[]> => {
  const contactListId = queryKey[1];
  const res = await AxiosConfig.instance.get(`${ROUTE}${contactListId}/groups`);
  return res.data;
};

export const createGroup = tryCatch(createGroupAction);
export const editGroup = tryCatch(editGroupAction);
export const deleteGroup = tryCatch(deleteGroupAction);
export const getAllGroups = tryCatch(getAllGroupsAction);
