import React, { useEffect, useMemo, useState } from "react";
import { HomePro } from "@witivio_teamspro/home-pro-sdk";
import { Flex, Header, Loader, Provider, teamsDarkTheme, teamsHighContrastTheme, teamsTheme, Text, ThemePrepared } from "@fluentui/react-northstar";
import { CardPersonBasic } from "./CardPersonBasic";
import { IContact } from "../../interfaces/IContact/IContact";
import { translations } from "../../translations";
import { checkContactList } from "../../apis/actions/contactListAction";
import Pagination from "../../components/Pagination/Pagination";
import { searchContact } from "../../apis/actions/contactAction";
import { ISearchQuery } from "../../interfaces/IContact/ISearchQuery";
import { BehaviorSubject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import NoContentIcon from "../../icons/NoContentIcon";
import { useMsTeamsSelector, useTranslate } from "front";

export interface ITranslations {
  en: Record<string, string>;
  fr: Record<string, string>;
  de: Record<string, string>;
  it: Record<string, string>;
  es: Record<string, string>;
  pt: Record<string, string>;
}

export interface IWidgetTheme {
  theme: ThemePrepared;
  locale: string;
  className: string;
  lang: Record<string, string>;
  onMobile: boolean;
  tenantDomain: string;
}

const THEMES = {
  default: teamsTheme,
  dark: teamsDarkTheme,
  contrast: teamsHighContrastTheme,
};

interface ITHEMES {
  default: ThemePrepared;
  dark: ThemePrepared;
  contrast: ThemePrepared;
}

const itemsPerPageGlobal = 9;

interface ISubject {
  contactListId: string;
  page: number;
}

const subject = new BehaviorSubject<ISubject>({
  contactListId: "",
  page: 1,
});

const fetchObservable = subject.pipe(distinctUntilChanged(), debounceTime(500));

export const Widget = () => {
  const t = useTranslate(translations);
  const { tenantId } = useMsTeamsSelector("tenantId");
  const [totalContacts, setTotalContacts] = useState<number>(0);
  const [contacts, setContacts] = useState<Array<IContact>>([]);
  const [contactListId, setContactListId] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [theme, setTheme] = useState<string>("default");
  const [context, setContext] = useState<IWidgetTheme>({
    locale: "en",
    theme: teamsTheme,
    lang: translations.en,
    tenantDomain: "",
    onMobile: false,
    className: "default",
  });
  const [searchQuery, setSearchQuery] = useState<ISearchQuery>({
    groupId: "",
    skip: 0,
    take: itemsPerPageGlobal,
    firstLetter: "",
    searchPattern: "",
  });

  useEffect(() => {
    const subscription = fetchObservable.subscribe(async (result: ISubject) => {
      if (!result.contactListId) return;
      let newSearchQuery = searchQuery;
      newSearchQuery.skip = (result.page - 1) * itemsPerPageGlobal;
      newSearchQuery.take = result.page * itemsPerPageGlobal;
      setSearchQuery(newSearchQuery);
      let res = await searchContact(result.contactListId, searchQuery, false, tenantId);
      // We save in localstorage only contacts of first page to speed up loading time of widget
      if (result.page === 1) {
        saveLocalContacts(result.contactListId, res.contacts);
        saveLocalTotalContacts(result.contactListId, res.total);
      }
      setContacts(res.contacts);
      setTotalContacts(res.total);
      setLoading(false);
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    HomePro.context.getContext().then((ctx) => {
      setContext((prevState) => ({
        ...prevState,
        onMobile: ctx.onMobile,
        tenantDomain: ctx.username,
        className: ctx.themeName,
        locale: ctx.locale,
        lang: translations[ctx.locale.substring(0, 2).toLocaleLowerCase() as keyof ITranslations],
        theme: THEMES[ctx.themeName as keyof ITHEMES],
      }));
    });
  }, [theme]);

  useEffect(() => {
    window.addEventListener("message", function (ev) {
      if ("homeProCallback" in ev.data) {
        if (ev.data.homeProCallback.action === "context.registerThemeChangeHandler") {
          setTheme(ev.data.homeProCallback.result);
        }
      }
    });
  }, []);

  useEffect(() => {
    (async () => {
      const settings = await HomePro.settings.getSettings();
      if (!settings.id) throw new Error("Contact list id is undefined");
      const isContactListExisting = await checkContactList(settings.id);
      if (!isContactListExisting) {
        console.error("contact list id does not exist");
        return;
      }
      setContactListId(settings.id);
      const localContacts = retrieveLocalContacts(settings.id);
      const localTotalContacts = retrieveLocalTotalContacts(settings.id);
      if (!!localContacts && !!localTotalContacts) {
        setContacts(localContacts);
        setTotalContacts(localTotalContacts);
        setLoading(false);
      }
    })();
  }, []);

  const saveLocalContacts = (contactListId: string, contacts: Array<IContact>) => {
    if (!!localStorage) localStorage.setItem("contacts_" + contactListId, JSON.stringify(contacts));
  };

  const saveLocalTotalContacts = (contactListId: string, total: number) => {
    if (!!localStorage) localStorage.setItem("total_" + contactListId, total + "");
  };

  const retrieveLocalContacts = (contactListId: string): Array<IContact> | undefined => {
    if (!!localStorage) {
      const contactsString = localStorage.getItem("contacts_" + contactListId);
      if (!contactsString?.startsWith("[") || !contactsString?.endsWith("]")) return undefined;
      return JSON.parse(contactsString);
    }
    return undefined;
  };

  const retrieveLocalTotalContacts = (contactListId: string): number => {
    if (!!localStorage) {
      const totalString = localStorage.getItem("total_" + contactListId);
      if (!totalString) return 0;
      try {
        return parseInt(totalString);
      } catch (e) {
        return 0;
      }
    }
    return 0;
  };

  const handlePage = (currentPage: number) => {
    setCurrentPage(currentPage);
    setLoading(true);
    subject.next({
      contactListId: contactListId,
      page: currentPage,
    });
  };

  useEffect(() => {
    if (!contactListId) return;
    subject.next({
      contactListId: contactListId,
      page: currentPage,
    });
  }, [contactListId]);

  useEffect(() => {
    if (contactListId && contacts) {
      setLoading(false);
    }
  }, [contacts, totalContacts]);

  return useMemo(() => {
    let content: JSX.Element;
    if (loading) content = <Loader style={{ height: "100vh" }} />;
    else
      content = (
        <Flex fill style={{ height: "100vh", overflow: "auto" }}>
          {contacts.length === 0 ? (
            <div className="mainImageAnyContactFoundForWidget">
              <NoContentIcon />
              <Header as="h3" className="header" content={t("MessageAnyContact")} />
              <Text>{t("MessageAnyContactDesc")}</Text>
            </div>
          ) : (
            <>
              <Pagination total={totalContacts} take={itemsPerPageGlobal} page={currentPage} changePage={handlePage} />
              {(contacts: any) => (
                <Flex fill wrap styles={{ marginTop: "10px" }}>
                  {contacts.map((person: IContact, key: number) => {
                    return <CardPersonBasic key={key} person={person} tenantId={context.tenantDomain} />;
                  })}
                </Flex>
              )}
            </>
          )}
        </Flex>
      );
    return <Provider theme={context.theme}>{content}</Provider>;
  }, [loading, context, contacts, totalContacts, currentPage]);
};
