import DisplayByCard from "../../components/DisplayByCard/DisplayByCard";
import DisplayByGroup from "../../components/DisplayByGroup/DisplayByGroup";
import DisplayByList from "../../components/DisplayByList/DisplayByList";
import { setModalWithData, useTranslate } from "front";
import { useContactListCache } from "../../hooks/useQuery/useContactListCache";
import { useUsersCache } from "../../hooks/useQuery/useUsersCache";
import { IDisplayMode } from "../../interfaces/IDisplayMode";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppDispatch, ReduxStoreState } from "../../redux/store";
import { translations } from "../../translations";
import { getDisplayMode } from "../../utils/getDisplayMode";
import { IDialogs } from "../../interfaces/IDialog/IDialogs";

export const useContactList = () => {
  const t = useTranslate(translations);
  const { searchPattern } = useSelector((s: ReduxStoreState) => s.pagination);
  const { queryContactList } = useContactListCache();
  const { queryUsers } = useUsersCache();
  const dispatchCtx = useDispatch<IAppDispatch>();

  const createContact = useCallback(() => {
    dispatchCtx(setModalWithData({ isOpen: IDialogs.CONTACT, data: { edit: true, contact: { id: "" } } }));
  }, []);

  const currentLetter = useSelector((s: ReduxStoreState) => s.pagination.firstLetter);

  const displayUsers = useMemo((): JSX.Element => {
    if (!queryContactList.data || !queryUsers.data?.contacts) return <></>;
    const { defaultDisplayMode } = queryContactList.data;
    const displayMode = getDisplayMode(defaultDisplayMode);
    const map = {
      [IDisplayMode.CARD]: <DisplayByCard users={queryUsers.data.contacts} />,
      [IDisplayMode.LIST]: <DisplayByList users={queryUsers.data.contacts} />,
      [IDisplayMode.GROUP]: <DisplayByGroup />,
    };
    return map[displayMode];
  }, [queryContactList.data, queryUsers.data?.contacts, currentLetter]);

  return {
    contactList: queryContactList.data,
    displayUsers,
    hasData: !!queryUsers.data?.total,
    hasError: queryContactList.error || queryUsers.error,
    t,
    createContact,
    searchPattern,
  };
};
