import React, { useMemo } from "react";
import "./ContactDialog.scss";
import { Button, CloseIcon, Divider, Flex, Text } from "@fluentui/react-northstar";
import { useContactDialog } from "./ContactDialog.logic";
import { ITabs } from "../../../../interfaces/IContact/ITabs";
import DialogBanner from "../../../../components/DialogBanner/DialogBanner";
import ContactInput from "../../../../components/ContactInput/ContactInput";
import ContactTabs from "../../../../components/ContactTabs/ContactTabs";
import CompanyTabs from "../../../../components/CompanyTabs/CompanyTabs";
import GroupTabs from "../../../../components/GroupTabs/GroupTabs";
import NoteTabs from "../../../../components/NoteTabs/NoteTabs";
import QuickActionBar from "../../../../components/QuickActionBar/QuickActionBar";
import ContactImageLoader from "../../../../components/ContactImageLoader/ContactImageLoader";
import { generateFormField } from "../../../../utils/generateFormField";
import { IFormInput } from "../../../../interfaces/IFormInput/IFormInput";
import { NO_INPUT_VALUE } from "../../../../const/const";
import { isObjectEmpty } from "front";

const ContactDialog = () => {
  const logic = useContactDialog();

  const nameAndJob: IFormInput[] = useMemo(
    () => [
      generateFormField("name", logic.defaultValues, <></>, { required: true, maxLength: 50 }, true, logic.t),
      generateFormField("jobTitle", logic.defaultValues, <></>, { maxLength: 50 }, true, logic.t),
    ],
    [logic.contact, logic.defaultValues, logic.t]
  );

  const tabs = useMemo(
    () => [
      {
        key: ITabs.CONTACT,
        error: logic.errors.internet || logic.errors.phones?.mobile,
      },
      {
        key: ITabs.COMPANY,
        error: logic.errors.phones?.businessPhone || logic.errors.phones?.businessFax,
      },
      {
        key: ITabs.GROUPS,
        error: false,
      },
      {
        key: ITabs.NOTES,
        error: false,
      },
    ],
    [logic.errors.internet, logic.errors.phones]
  );

  return (
    <>
      <div className="contactDialog-shadow"></div>
      <Flex hAlign="center" vAlign="center" column className="contactDialog-container">
        <div className="contactDialog-card-container">
          <Flex className="contactDialog-top-container" hAlign="center" vAlign="center" space="between">
            <ContactImageLoader onRef={logic.onContactDialogRef} readOnly={!logic.canModify} defaultImage={logic.contact?.picture} setValue={logic.setValue} name={logic.contact?.name} />
            <Button icon={<CloseIcon />} text={!logic.isLightTheme} iconOnly flat={logic.isLightTheme} primary={logic.isLightTheme} onClick={logic.closeDialog} />
          </Flex>
          <Flex className="contactDialog-bottom-container" column>
            {logic.canModify && (
              <Flex className="contactDialog-btns-modify-container" gap="gap.small">
                <Button content={logic.t("Cancel")} onClick={() => logic.switchModify(false)} disabled={!!logic.banner?.show || logic.isLoading} />
                <Button
                  content={logic.t("Save")}
                  disabled={!logic.isDirty || !isObjectEmpty(logic.errors) || !!logic.banner?.show || logic.isLoading}
                  primary
                  onClick={logic.handleSubmit(logic.onSubmit)}
                />
              </Flex>
            )}
            <Flex column className="contactDialog-titles-container">
              <Flex column className="contactDialog-titles">
                {logic.canModify ? (
                  <div style={{ paddingLeft: ".25rem" }}>
                    {nameAndJob.map((i) => (
                      <ContactInput key={i.formKey} {...i} canModify={logic.canModify} control={logic.control} errors={logic.errors} />
                    ))}
                  </div>
                ) : (
                  <>
                    <Text content={logic.contact?.name} size="large" weight="bold" />
                    <Text content={logic.contact?.jobTitle ? logic.contact.jobTitle : NO_INPUT_VALUE} />
                  </>
                )}
              </Flex>

              <QuickActionBar canModify={logic.canModify} contact={logic.contact} switchModify={logic.switchModify} displayDeleteContactBanner={logic.displayDeleteContactBanner} />
            </Flex>
            <Flex className="contactDialogs-tabs-items" vAlign="center" hAlign="start">
              {tabs.map((t) => (
                <Button
                  key={t.key}
                  content={logic.t(t.key)}
                  text
                  primary={logic.currentTab === t.key}
                  style={{ color: t.error ? "red" : "" }}
                  onClick={() => logic.handleTabs(t.key)}
                  styles={{ minWidth: "initial", padding: "1rem" }}
                />
              ))}
            </Flex>
            <Divider />
            <div style={{ padding: "1rem 0" }} className="contactDialog-tabs-content">
              {logic.currentTab === ITabs.CONTACT && (
                <ContactTabs canModify={logic.canModify} contact={logic.contact} control={logic.control} defaultValues={logic.defaultValues} errors={logic.errors} />
              )}
              {logic.currentTab === ITabs.COMPANY && (
                <CompanyTabs
                  canModify={logic.canModify}
                  contact={logic.contact}
                  control={logic.control}
                  defaultValues={logic.defaultValues}
                  errors={logic.errors}
                  setValue={logic.setValue}
                  watch={logic.watch}
                />
              )}

              {logic.currentTab === ITabs.GROUPS && <GroupTabs canModify={logic.canModify} contact={logic.contact} control={logic.control} watch={logic.watch} />}
              {logic.currentTab === ITabs.NOTES && <NoteTabs canModify={logic.canModify} control={logic.control} />}
            </div>
          </Flex>
          {logic.banner && (
            <DialogBanner
              show={!!logic.banner.show}
              txt={logic.banner.txt}
              confirmLabel={logic.banner.confirmLabel}
              cancelLabel={logic.banner.cancelLabel}
              confirmAction={logic.banner.confirmAction}
              cancelAction={logic.banner.cancelAction}
            />
          )}
        </div>
      </Flex>
    </>
  );
};

export default ContactDialog;
