import { SyntheticEvent, useReducer, useEffect, useCallback, useState } from "react";
import { reducer, initialState, IAction, componentIsUnmounting } from "./Configure.reducer";
import { IDisplayMode } from "../../interfaces/IDisplayMode";
import { DropdownProps } from "@fluentui/react-northstar";
import * as microsoftTeams from "@microsoft/teams-js";
import { IContactList } from "../../interfaces/IContact/IContactList";
import { IContactListCreated } from "../../interfaces/IContact/IContactListCreated";
import { useMsTeamsSelector, useTranslate } from "front";
import { createContactList } from "../../apis/actions/contactListAction";
import { translations } from "../../translations";

export const useConfigure = () => {
  // Services
  const t = useTranslate(translations);
  const { channelId, tenantId, userMail } = useMsTeamsSelector("channelId", "tenantId", "userMail");

  // State
  const [state, dispatch] = useReducer(reducer, { ...initialState });
  const [entityId, setEntityId] = useState<string | undefined>(undefined);

  // UseEffect
  useEffect(() => {
    getEntityId();
    microsoftTeams.pages.config.setValidityState(true);
    return () => componentIsUnmounting();
  }, []);

  const getEntityId = async () => {
    const { entityId } = await microsoftTeams.pages.getConfig();
    setEntityId(entityId);
  };

  useEffect(() => {
    microsoftTeams.pages.config.registerOnSaveHandler(async (saveEvent) => await onSubmit(saveEvent));
  }, [state.displayMode]);

  const handleDropDown = useCallback((_: SyntheticEvent | null, item: DropdownProps): void => {
    const value = item.value;
    if (!value) return;
    const displayMode = value === t(IDisplayMode.CARD) ? IDisplayMode.CARD : IDisplayMode.LIST;
    const payload = { ...state, displayMode };
    dispatch({ type: IAction.UPDATE_INPUT, payload });
  }, []);

  const onSubmit = async (saveEvent: microsoftTeams.pages.config.SaveEvent): Promise<void> => {
    const defaultDisplayMode = state.displayMode === IDisplayMode.CARD ? 0 : 1;
    const params: IContactList = {
      channelId,
      tenantId,
      defaultDisplayMode,
      chargebeeSubscriptionId: "",
      isPremium: false,
      createdBy: userMail,
      updatedBy: userMail,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    const res: IContactListCreated = await createContactList({ tenantId, params });

    // Create custom tab in Teams
    await microsoftTeams.pages.config.setConfig({
      contentUrl: `https://${window.location.host}/contact/${res.id}`,
      removeUrl: `https://${window.location.host}/remove/${res.id}`,
      entityId: res.id,
      suggestedDisplayName: "Contacts Pro",
    });
    saveEvent.notifySuccess();
  };

  const dropDownItems = [t(IDisplayMode.CARD), t(IDisplayMode.LIST)];

  return { state, t, dropDownItems, handleDropDown, entityId };
};
