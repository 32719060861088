import { QUERY_KEY_USERS } from "../../const/queryKey";
import { IContact } from "../../interfaces/IContact/IContact";
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { IAppDispatch, ReduxStoreState } from "../../redux/store";
import { queryOptions } from "./queryOptions";
import { createContact, deleteContact, editContact, getAllContacts, importContact } from "../../apis/actions/contactAction";
import { useQueryCache } from "./useQueryCache";
import { handleExpiredToken, setErrorMessage, useMsTeamsSelector, useTranslate } from "front";
import { translations } from "../../translations";
import { IQueryCacheData } from "../../interfaces/ICache/IQueryCache";
import { queryClient } from "../../index";
import { setPagination } from "../../redux/reducer/PaginationReducer";

enum IUsersCacheCreateAction {
  IS_AFTER = "is_after",
  IS_BETWEEN = "is_between",
  IS_BEFORE = "is_before",
  MUTATE = "mutate",
}

export const useUsersCache = () => {
  const appId = useSelector((s: ReduxStoreState) => s.appConfiguration.appId);
  const { tenantId } = useMsTeamsSelector("tenantId");
  const pagination = useSelector((s: ReduxStoreState) => s.pagination);
  const dispatch = useDispatch<IAppDispatch>();
  const { mutate } = useQueryCache();
  const t = useTranslate(translations);

  const removeNextQueries = (page: number, pages: number, take: number, includeCurrentQuery?: boolean) => {
    const startIndex = includeCurrentQuery ? page : page + 1;
    for (let i = startIndex; i <= pages; i++) {
      queryClient.removeQueries([QUERY_KEY_USERS, appId, { ...pagination, skip: (i - 1) * take, currentPage: i }, tenantId]);
    }
  };

  const queryUsers = useQuery<{ total: number; contacts: IContact[] }>([QUERY_KEY_USERS, appId, pagination, tenantId], getAllContacts, {
    ...queryOptions,
    enabled: !!appId,
    staleTime: Infinity,
    refetchOnMount: false,
    ...handleExpiredToken,

  });

  const mutateCreateContact = useMutation(createContact, {
    ...handleExpiredToken,
    onSuccess: async (res: IContact) => {
      if (!queryUsers.data) return;

      queryClient.setQueriesData([QUERY_KEY_USERS, appId], (oldData: any) => ({
        ...oldData,
        total: oldData.total + 1,
      }));
      createMutationUpdateQuery(res);
    },
    onError: () => {
      dispatch(setErrorMessage(t("CreateUserError")));
    },
  });

  const mutateDeleteContact = useMutation(deleteContact, {
    ...handleExpiredToken,
    onSuccess: (_: undefined, variable: { id: string; isLonely: boolean }) => {
      if (!queryUsers.data) return;

      const shouldGoToPrevPage = pagination.currentPage !== 1 && variable.isLonely ? pagination.currentPage - 1 : pagination.currentPage;

      const skip = (shouldGoToPrevPage - 1) * pagination.take;

      const totalPages = Math.ceil(queryUsers.data.total / pagination.take);

      queryClient.setQueriesData([QUERY_KEY_USERS, appId], (oldData: { total: number; contacts: IContact[] } | undefined) => {
        if (!oldData) return { total: 0, contacts: [] };
        const firstDisabledElement = oldData.contacts.findIndex((od) => od.isDisabled);
        if (firstDisabledElement !== -1) oldData.contacts[firstDisabledElement]!.isDisabled = false;
        return {
          ...oldData,
          total: oldData.total - 1,
        };
      });

      if (pagination.currentPage !== totalPages) {
        removeNextQueries(pagination.currentPage, totalPages, pagination.take, true);
      } else {
        const contacts = queryUsers.data.contacts.filter((item) => item.id !== variable.id);
        mutate({
          data: { total: queryUsers.data.total - 1, contacts },
          queryKey: [QUERY_KEY_USERS, appId, pagination, tenantId],
        });
      }

      dispatch(
        setPagination({
          ...pagination,
          currentPage: shouldGoToPrevPage,
          skip,
        })
      );
    },
    onError: () => {
      dispatch(setErrorMessage(t("DeleteUserError")));
    },
  });

  const mutateEditContact = useMutation(editContact, {
    ...handleExpiredToken,
    onSuccess: (
      _: undefined,
      variable: {
        contactListId: string;
        contactId: string;
        contact: IContact;
      }
    ) => {
      if (!queryUsers.data) return;
      const copyQuery = queryUsers.data ? [...queryUsers.data.contacts] : [];
      const modifiedUserIndex = copyQuery.findIndex((item) => item.id === variable.contactId);
      if (modifiedUserIndex === -1) return;
      copyQuery.splice(modifiedUserIndex, 1, variable.contact);
      const dataCache: IQueryCacheData = {
        data: { total: queryUsers.data.total, contacts: [...copyQuery] },
        queryKey: [QUERY_KEY_USERS, appId, pagination, tenantId],
      };
      mutate(dataCache);
    },
    onError: () => {
      dispatch(setErrorMessage(t("EditUserError")));
    },
  });

  const createMutationUpdateQuery = (res: IContact) => {
    const data: { total: number; contacts: IContact[] } | undefined = queryClient.getQueryData([QUERY_KEY_USERS, appId, pagination, tenantId]);
    if (!data) return;

    const { take, currentPage } = pagination;
    const totalPages = Math.ceil(data.total / take);
    const firstContact = data.contacts[0];
    const lastContact = data.contacts[take - 1];
    const contactLength = data.contacts.length;

    let action: IUsersCacheCreateAction = IUsersCacheCreateAction.MUTATE;

    // IS AFTER
    if (lastContact && isAfter(res.name, lastContact.name)) action = IUsersCacheCreateAction.IS_AFTER;

    // IS BETWEEN
    if (lastContact && firstContact && isBetween(res.name, firstContact.name, lastContact.name)) action = IUsersCacheCreateAction.IS_BETWEEN;

    // IS BEFORE
    if (firstContact && isBefore(res.name, firstContact.name)) action = IUsersCacheCreateAction.IS_BEFORE;

    // NO CONTACT
    if (contactLength !== take) action = IUsersCacheCreateAction.MUTATE;

    switch (action) {
      case IUsersCacheCreateAction.IS_AFTER:
        removeNextQueries(currentPage, totalPages, take);
        return;
      case IUsersCacheCreateAction.IS_BETWEEN:
        contactIsBetween(res, data.total, currentPage, totalPages, take);
        return;
      case IUsersCacheCreateAction.IS_BEFORE:
        queryClient.removeQueries([QUERY_KEY_USERS, appId]);
        return;
      case IUsersCacheCreateAction.MUTATE:
        mutateContact(res, data.total);
        return;
    }
  };

  const mutateImportContact = useMutation(importContact, {...handleExpiredToken});

  const mutateContact = (res: IContact, total: number) => {
    const contacts = [...(queryUsers.data?.contacts ?? []), res];
    contacts.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    mutate({
      data: { total, contacts },
      queryKey: [QUERY_KEY_USERS, appId, pagination, tenantId],
    });
  };

  const contactIsBetween = (res: IContact, total: number, page: number, pages: number, take: number) => {
    queryUsers.data?.contacts.pop();
    const contactsWithoutLast = [...(queryUsers.data?.contacts ?? [])];
    const contacts = [...contactsWithoutLast, res].sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
    mutate({
      data: { total, contacts },
      queryKey: [QUERY_KEY_USERS, appId, pagination, tenantId],
    });
    removeNextQueries(page, pages, take);
  };

  return {
    queryUsers,
    mutateCreateContact,
    mutateDeleteContact,
    mutateImportContact,
    mutateEditContact,
  };
};

const isBefore = (string1: string, string2: string): boolean => string1 < string2;
const isAfter = (string1: string, string2: string): boolean => string1 > string2;
const isBetween = (string1: string, first: string, last: string): boolean => isAfter(string1, first) && isBefore(string1, last);
