import { EmailIcon, GlobeIcon, LinkedInIcon, PhoneIcon } from "@fluentui/react-northstar";
import ContactInput from "../../components/ContactInput/ContactInput";
import { IContact } from "../../interfaces/IContact/IContact";
import React, { useMemo } from "react";
import { useContactTabs } from "./ContactTabs.logic";
import { Control, FieldErrors } from "react-hook-form";
import XIcon from "../../icons/XIcon";
import FacebookIcon from "../../icons/FacebookIcon";
import { generateFormField } from "../../utils/generateFormField";
import { IFormInput } from "../../interfaces/IFormInput/IFormInput";

const ContactTabs = (props: { defaultValues: Omit<IContact, "updatedAt" | "createdAt">; contact: IContact | undefined; canModify: boolean; control: Control<IContact, any>; errors: FieldErrors }) => {
  const logic = useContactTabs();

  const contactTabs: IFormInput[] = useMemo(
    () => [
      generateFormField("email", props.defaultValues, <EmailIcon outline style={{ margin: ".75rem" }} />, { pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/ }, true, logic.t),
      generateFormField("mobile", props.defaultValues, <PhoneIcon outline style={{ margin: ".75rem" }} />, { pattern: /^([\d \(\)-\/+]){1,20}$/ }, true, logic.t),
      generateFormField(
        "website",
        props.defaultValues,
        <GlobeIcon outline style={{ margin: ".75rem" }} />,
        { pattern: /^(http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/ },
        true,
        logic.t
      ),
      generateFormField(
        "linkedin",
        props.defaultValues,
        <LinkedInIcon outline style={{ margin: ".75rem" }} />,
        { pattern: /^(http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/ },
        true,
        logic.t
      ),
      generateFormField(
        "facebook",
        props.defaultValues,
        <FacebookIcon style={{ margin: ".75rem", width: "20px", height: "17.5px" }} />,
        { pattern: /^(http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/ },
        true,
        logic.t
      ),
      generateFormField(
        "twitter",
        props.defaultValues,
        <XIcon
          style={{
            margin: ".75rem",
            width: "20px",
            height: "17.5px",
          }}
          svgColor={logic.isLightTheme ? "black" : "white"}
        />,
        { pattern: /^(http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/ },
        true,
        logic.t
      ),
    ],
    [props.contact, props.defaultValues, logic.t, logic.isLightTheme]
  );

  return (
    <>
      <div className="contactDialog-contact-tab-container">
        {contactTabs.map((i) => (
          <ContactInput key={i.formKey} {...i} canModify={props.canModify} control={props.control} errors={props.errors} />
        ))}
      </div>
    </>
  );
};

export default ContactTabs;
