import React, { ReactElement, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TelemetryService, { appInsightInstance } from "./services/TelemetryService/TelemetryService";
import { AppViews } from "./const/AppViews";
import { AppView } from "./interfaces/AppView/AppView";
import { Loader, Provider, ThemeInput } from "@fluentui/react-northstar";
import { AppViewData } from "./interfaces/AppView/AppViewData";
import { wrapWithErrorBoundary, Consent, useMsTeamsSelector, useAuthSelector, UpgradePremiumDialog, useConfigurationSelector, useAppInitializer, NewsDialog, IAppType } from "front";
import { translations } from "./translations";
import DialogsContainer from "./components/DialogsContainer/DialogsContainer";
import * as microsoftTeams from "@microsoft/teams-js";

export const App = () => {
  const { loaded } = useAppInitializer({ needInitializegraph: false });
  const msTeamsContext = useMsTeamsSelector("locale", "theme", "themeClass", "newTeams");
  translations.locale = msTeamsContext.locale;
  const { isTeamsApp } = useAuthSelector("isTeamsApp");
  const { data: config } = useConfigurationSelector("data");
  const wrapWithFluentUI = getFluentUIWrapper(msTeamsContext.locale, msTeamsContext.theme);
  const [entityId, setEntityId] = React.useState<string>("");

  useEffect(() => {
    const asyncms = async() =>{
      await microsoftTeams.app.initialize();
      const settings = await microsoftTeams.pages.getConfig()
        if (settings.entityId) {
          setEntityId(settings.entityId)
        }
    }
    asyncms();
  },[isTeamsApp])
  
  if (!loaded) return wrapWithFluentUI(<Consent />);
  return wrapWithErrorBoundary(
    wrapWithFluentUI(
      <TelemetryService>
        {loaded ? (
          <div id={msTeamsContext.newTeams ? "new-teams" : "old-teams"}>
            <NewsDialog AppType={IAppType.CONTACT} />
            <UpgradePremiumDialog appId={config.adminCenterAppId} appInsight={appInsightInstance} />
            <DialogsContainer />
            <Routes>
              {renderRoutes(AppViews)}
              {!isTeamsApp && <Route path="*" element={<Navigate to={"home/" + process.env.REACT_APP_CALENDAR_MOCK_ID} replace />} />}
              {entityId && <Route path="/" element={<Navigate to={"contact/" + entityId} replace />} />}
            </Routes>
          </div>
        ) : (
          <Loader styles={{ height: "100vh" }} />
        )}
      </TelemetryService>
    )
  );
};
const renderRoutes = (appViews: Record<AppView, AppViewData>) => {
  return Object.values(appViews).map((view) => <Route key={view.path} path={view.path} element={<view.element />} />);
};

const getFluentUIWrapper = (locale: string, theme: ThemeInput) => (children: ReactElement) =>
  (
    <Provider lang={locale} theme={theme}>
      {children}
    </Provider>
  );
