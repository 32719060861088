import { AxiosConfig, tryCatch } from "front";
import { IContact } from "interfaces/IContact/IContact";
import { IContactsSummary } from "interfaces/IContact/IContactSummary";
import { ISearchQuery } from "interfaces/IContact/ISearchQuery";

const createContactAction = async (data: { contactListId: string; contact: IContact }): Promise<IContact> => {
  const res = await AxiosConfig.instance.post(`contact-lists/${data.contactListId}/contacts`, data.contact);
  return res.data;
};

const editContactAction = async (data: { contactListId: string; contactId: string; contact: IContact }): Promise<void> => {
  await AxiosConfig.instance.put(`contact-lists/${data.contactListId}/contacts/${data.contactId}`, data.contact);
};

const deleteContactAction = async (data: { id: string; isLonely: boolean }): Promise<void> => {
  await AxiosConfig.instance.delete(`contact-lists/${data.id}`);
};

const importContactAction = async (data: FormData): Promise<void> => {
  await AxiosConfig.instance.post(`contacts/imports`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const exportContactAction = async (data: { contactListId: string; email: string; tenantId: string; groupId: string; channelId: string; contactListName: string }): Promise<void> => {
  await AxiosConfig.instance.post(`contacts/exports`, data);
};

const getContactImportTemplateAction = async () => {
  const data = await AxiosConfig.instance.get(`contacts/templates`, { responseType: "blob" });
  return data.data;
};

const getAllContactsAction = async ({ queryKey }: { queryKey: any[] }): Promise<IContact[]> => {
  const contactListId = queryKey[1];
  const { skip, take, searchPattern, firstLetter } = queryKey[2];
  const tenantId = queryKey[3];
  const query = `skip=${skip}&take=${take}&searchPattern=${searchPattern}&firstLetter=${firstLetter}`;
  const res = await AxiosConfig.instance.get(`contact-lists/${contactListId}/contacts/tenantId/${tenantId}/search?${query}`);
  return res.data;
};

export const searchContact = async (contactListId: string, searchQuery: ISearchQuery, modeGroup: boolean = false, tenantId: string): Promise<IContactsSummary> => {
  let skipTake = `Skip=${searchQuery.skip}&Take=${searchQuery.take}`;
  let groupId = `GroupId=${searchQuery.groupId}`;
  let mode = `ModeGroup=${modeGroup}`;

  let search = `firstLetter=${searchQuery.firstLetter}&searchPattern=${searchQuery.searchPattern}&${skipTake}&${groupId}&${mode}`;
  const res = await AxiosConfig.instance.get(`contact-lists/${contactListId}/contacts/tenantId/${tenantId}/search?${search}`);
  return res.data;
};

export const getContact = async (contactListId: string, contactId: string): Promise<IContact> => {
  const res = await AxiosConfig.instance.get(`contact-lists/${contactListId}/contacts/${contactId}`);
  return res.data;
};

const searchAllContactsAction = async (params: { contactListId: string; search: string }) => {
  const res = await AxiosConfig.instance.get(`contact-lists/${params.contactListId}/contacts/search/${params.search}`);
  return res.data;
};

export const createContact = tryCatch(createContactAction);
export const editContact = tryCatch(editContactAction);
export const deleteContact = tryCatch(deleteContactAction);
export const importContact = tryCatch(importContactAction);
export const exportContact = tryCatch(exportContactAction);
export const getContactImportTemplate = tryCatch(getContactImportTemplateAction);
export const getAllContacts = tryCatch(getAllContactsAction);
export const searchAllContacts = tryCatch(searchAllContactsAction);
