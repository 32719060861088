import { QUERY_KEY_CONTACT_LIST } from "../../const/queryKey";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { IAppDispatch } from "../../redux/store";
import { queryOptions } from "./queryOptions";
import { IContactListCreated } from "../../interfaces/IContact/IContactListCreated";
import { getContactList, updateContactList } from "../../apis/actions/contactListAction";
import { useCallback } from "react";
import { handleExpiredToken, setAppConfiguration } from "front";
import { useQueryCache } from "./useQueryCache";
import { IQueryCacheData } from "../../interfaces/ICache/IQueryCache";
import { AppViews } from "../../const/AppViews";
import { AppView } from "../../interfaces/AppView/AppView";

export const useContactListCache = () => {
  const dispatch = useDispatch<IAppDispatch>();
  const { mutate } = useQueryCache();

  const onSuccess = useCallback((res: IContactListCreated) => {
    const appPathName = AppViews[AppView.Contact]?.path.split("/")[1] ?? "";
    const appOwner = res?.createdBy ?? "";
    dispatch(setAppConfiguration({ appPathName, appId: res?.id ?? "", appOwner, appName: "Contacts Pro", app: "Contacts Pro", from: "Contact" }));
  }, []);

  const queryContactList = useQuery<IContactListCreated>([QUERY_KEY_CONTACT_LIST], getContactList, {
    ...queryOptions,
    staleTime: Infinity,
    onSuccess,
    retryOnMount: false,
    refetchOnMount: false,
    ...handleExpiredToken
  });

  const { mutate: updateContactListMutate } = useMutation(updateContactList, {
    ...handleExpiredToken,
    onSuccess: (_, variable: any) => {
      const dataCache: IQueryCacheData = {
        data: { ...variable.params },
        queryKey: [QUERY_KEY_CONTACT_LIST],
      };
      mutate(dataCache);
    },
  });

  return { queryContactList, updateContactListMutate };
};
