import { AxiosConfig, ISubscription, tryCatch } from "front";
import { IContactList } from "interfaces/IContact/IContactList";
import { IContactListCreated } from "interfaces/IContact/IContactListCreated";
import * as microsoftTeams from "@microsoft/teams-js";

const ROUTE = "/contact-lists/";
const createContactListAction = async (data: { tenantId: string; params: IContactList }): Promise<IContactListCreated> => {
  const { tenantId, params } = data;
  const res = await AxiosConfig.instance.post(`${ROUTE}${tenantId}`, params);
  return res.data;
};

const updateContactListAction = async (data: { contactListId: string; params: IContactList }): Promise<void> => {
  const { contactListId, params } = data;
  await AxiosConfig.instance.put(`${ROUTE}${contactListId}`, params);
};

const getContactListAction = async (): Promise<IContactListCreated> => {
  const { entityId } = await microsoftTeams.pages.getConfig();
  const res = await AxiosConfig.instance.get(`${ROUTE}${entityId}`);
  return res.data;
};

const checkContactListId = async (contactListId: string) => {
  const res = await AxiosConfig.instance.get(`${ROUTE}${contactListId}/check`);
  return res.data;
};

const deleteContactListId = async (contactListId: string): Promise<void> => {
  return await AxiosConfig.instance.delete(`${ROUTE}${contactListId}`);
};

const getLimitationAction = async (tenantId: string): Promise<{ subscription: ISubscription; maxContact: number }> => {
  const res = await AxiosConfig.instance.get(`${ROUTE}/tenantId/${tenantId}/limitation`);
  return res.data;
};

export const createContactList = tryCatch(createContactListAction);
export const updateContactList = tryCatch(updateContactListAction);
export const getContactList = tryCatch(getContactListAction);
export const checkContactList = tryCatch(checkContactListId);
export const deleteContactList = tryCatch(deleteContactListId);
export const getLimitation = tryCatch(getLimitationAction);
