import { AxiosConfig, tryCatch } from "front";

const ROUTE = "/contact-lists/";

const getParticipantsFromGroupAction = async ({ queryKey }: { queryKey: string[] }) => {
  const [_, contactListId, groupId, tenantId] = queryKey;
  const res = await AxiosConfig.instance.get(`${ROUTE}${contactListId}/contacts/tenantId/${tenantId}/group/${groupId}`);
  return res.data;
};

export const getParticipantFromGroup = tryCatch(getParticipantsFromGroupAction);
